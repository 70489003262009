<template lang="pug">
  AccountForm(
    :roles="roles"
    :roles-loading="rolesLoading"
    :save-action="createUser"
    :after-save="() => scrollTo({ target: '.create-account-heading', block: 'center' })"
    :account-id-already-exists-request="checkAccountIdExists"
    :user-account="newUser"
    :form-title="$t('account_management.tabs.create_account')"
  )
</template>

<script>
  // store modules
  import accountsModule from "@/config/store/accounts"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withScrollTop from "@/mixins/withScrollTop"

  const accountsMixin = withStoreModule(accountsModule, {
    name: "accounts",
    readers: {
      accounts: "items",
      accountsLoading: "loading",
      invalidFields: "invalidFields"
    },
    actions: { createUser: "CREATE_ITEM", checkAccountIdExists: "CHECK_ACCOUNT_ID_EXISTS" }
  })

  export default {
    props: {
      roles: {
        type: Array,
        default: () => new Array()
      },
      rolesLoading: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AccountForm: () => import("@/components/AccountManagement/AccountForm")
    },

    mixins: [accountsMixin, withScrollTop],

    data() {
      return {
        newUser: {
          first_name: "",
          last_name: "",
          account_id: "",
          password: "",
          password_confirmation: "",
          email: "",
          phone_number: "",
          role_id: undefined,
          allowed_shop_ids: [],
          allowed_inventory_group_ids: [],
          allowed_car_class_ids: []
        }
      }
    }
  }
</script>

<style lang="sass" scoped></style>
